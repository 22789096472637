import React, { useState, useEffect } from 'react';
import './GuildLeaderboard.css';
import Footer from './Footer';
import Navbar from './Navbar';
import { formatDistanceToNow } from 'date-fns';

interface GuildData {
  _id: string;
  name: string;
  members: number;
  networth: number;
  level: number;
  weight: number;
  skills: number;
  slayers: number;
  catacombs: number;
  lastsync: string; 
}

function format(value: number): string {
  const suffixes = ['', 'K', 'M', 'B'];
  let index = 0;

  while (value >= 1000 && index < suffixes.length - 1) {
    value /= 1000;
    index++;
  }

  const suffix = suffixes[index];
  return value.toFixed(2) + suffix;
}

function formatLevel(level: number): string {
  return parseFloat(level.toString()).toFixed(1);
}

function GuildLeaderboard() {
  const [guildData, setGuildData] = useState<GuildData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://isleofducks.net/api/t.php');
        const responseData = await response.json();
  
        if (Array.isArray(responseData.guild_data)) {
          const sortedGuildData = responseData.guild_data.map((guild: GuildData) => ({
            ...guild,
            level: Number(guild.level)
          })).sort((a: GuildData, b: GuildData) => b.level - a.level);
  
          setGuildData(sortedGuildData);
        } else {
          console.error('Error');
        }
      } catch (error) {
        console.error('Error: ', error);
      }
    };
  
    fetchData();
  }, []);
  
  
  
  
  
  
  

  const getLastSyncRelative = (lastSync: string): string => {
    const syncDate = new Date(lastSync);
    return capitalize(formatDistanceToNow(syncDate, { addSuffix: true }));
  };  

  const capitalize = (s: string): string => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div>
      <Navbar />
      <div className="guildleaderboard-content">
        <h1>Isle of Ducks Guild Leaderboard</h1>
        <p>Leaderboards are currently under development, expect regular changes and slightly off statistics!</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Members</th>
              <th>Skyblock Level</th>
              <th>Networth</th>
              <th>Weight</th>
              <th>Skill Average</th>
              <th>Slayers</th>
              <th>Catacombs</th>
              <th>Last Sync</th>
            </tr>
          </thead>
          <tbody>
            {guildData.map((guild, index) => (
              <tr key={guild._id}>
                <td>{index + 1}</td>
                <td>{guild.name}</td>
                <td>{guild.members || 0}</td>
                <td>{formatLevel(guild.level || 0)}</td>
                <td>{format(guild.networth || 0)}</td>
                <td>{guild.weight !== null ? Math.floor(guild.weight) : 0}</td>
                <td>{guild.skills !== null ? guild.skills.toFixed(2) : 0}</td>
                <td>{guild.slayers !== null ? format(guild.slayers) : 0}</td>
                <td>{guild.catacombs !== null ? guild.catacombs.toFixed(2) : 0}</td>
                <td>{getLastSyncRelative(guild.lastsync)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
}

export default GuildLeaderboard;
