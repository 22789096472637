import React, { useState, useEffect } from 'react';
import './Home.css';
import Footer from './Footer';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

function Dashboard() {
  return (
    <body>
      <Navbar />
      <div className="home-content">
        <h1>Isle of Ducks</h1>
      </div>
      <Footer />
    </body>
  );
}

export default Dashboard;
