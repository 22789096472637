import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <div className="navbar">
        <Link to="/staff" className='navbar-link'>Staff</Link>
        {/* <div className="navbar-dropdown">
          <button className="navbar-link navbar-dropdown-btn">Leaderboards ▼</button>
          <div className="navbar-dropdown-content">
            <Link to="/leaderboard/guild" className='navbar-link'>Guild Leaderboard</Link>
            <Link to="/leaderboard/player" className='navbar-link'>Player Leaderboard</Link>
          </div>
        </div> */}
        <Link to="/" className='navbar-link-home'>🦆 Isle of Ducks</Link>
        <a href="https://discord.gg/isleofducks" className='navbar-link' target="_blank" rel="noopener noreferrer">Discord</a>
    </div>
  );
}

export default Navbar;
