import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './Home.css';

interface User {
  username: string;
  // Add other properties if needed
}

const Login: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://isleofducks.net:5000/auth/user', { withCredentials: true })
            .then(response => {
                setUser(response.data);
            })
            .catch(error => {
                console.log('Not authenticated');
            });
    }, []);

    const login = () => {
        window.location.href = 'http://isleofducks.net:5000/auth/discord';
    };

    const logout = () => {
        axios.get('http://isleofducks.net:5000/auth/logout', { withCredentials: true })
            .then(() => {
                setUser(null);
            });
    };

    console.log('Rendering Login component');

    return (
        <body>
            <Navbar />
            <div className="home-content">
                <div className="App">
                    <header className="App-header">
                        {user ? (
                            <>
                                <h2>Welcome, {user.username}</h2>
                                <button onClick={logout}>Logout</button>
                            </>
                        ) : (
                            <button onClick={login}>Login with Discord</button>
                        )}
                    </header>
                </div>
            </div>
            <Footer />
        </body>
    );
}

export default Login;
