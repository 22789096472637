import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Forms from './Forms';
import GuildLeaderboard from './GuildLeaderboard';
import Home from './Home';
import Login from './Login';
import PlayerLeaderboard from './PlayerLeaderboard';
import Staff from './Staff';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/forms" element={<Forms />} />
      <Route path="/leaderboard/guild" element={<GuildLeaderboard />} />
      <Route path="/leaderboard/player" element={<PlayerLeaderboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/staff" element={<Staff />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
