import './Staff.css';
import Footer from './Footer';
import Navbar from './Navbar';

function Staff() {
  return (
    <body>
        <Navbar />
        <div className="content">
            <h1>Staff</h1>
            <div className="role">
                <h2>Owner</h2>
                <img src="https://s.namemc.com/2d/skin/face.png?id=ce0073fcc1d002f3&scale=16"/>
                <p>Ducksicle</p>
            </div>
            <div className="role">
                <h2>Admin</h2>
                <ul>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=c42afd952f3edb72&scale=16"/>
                        <p>Chizurux</p>
                    </li>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=617655209a5c2df7&scale=16"/>
                        <p>Serendibite</p>
                    </li>
                </ul>
            </div>
            <div className="role">
                <h2>Moderator</h2>
                <ul>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=b84ac9a32c374ef1&scale=16"/>
                        <p>EmanCarrier</p>
                    </li>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=568395238ed2b379&scale=16"/>
                        <p>j2iscool</p>
                    </li>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=b1fef0467c0f7b6f&scale=16"/>
                        <p>J_forjoooooo</p>
                    </li>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=49e5b5885247106a&scale=16"/>
                        <p>Nonth</p>
                    </li>
                </ul>
            </div>
            <div className="role">
                <h2>Trainee</h2>
                <ul>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=aaf42b582a8bc9b2&scale=16"/>
                        <p>egorinho</p>
                    </li>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=aadec86788cc36c9&scale=16"/>
                        <p>Lunching</p>
                    </li>
                    <li>
                        <img src="https://s.namemc.com/2d/skin/face.png?id=303d269be185d319&scale=16"/>
                        <p>stanhope</p>
                    </li>
                </ul>
            </div>
        </div>
        <Footer />
    </body>
  );
}

export default Staff;
