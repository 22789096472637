import React, { useState, useEffect } from 'react';
import './PlayerLeaderboard.css';
import Footer from './Footer';
import Navbar from './Navbar';
import { formatDistanceToNow } from 'date-fns';

interface Player {
  name: string;
  networth: number;
  level: number;
  weight: number;
  skills: number;
  slayers: number;
  catacombs: number;
  lastsync: string; 
}

function format(value: number): string {
  const suffixes = ['', 'K', 'M', 'B'];
  let index = 0;

  while (value >= 1000 && index < suffixes.length - 1) {
    value /= 1000;
    index++;
  }

  const suffix = suffixes[index];
  return value.toFixed(2) + suffix;
}

function PlayerLeaderboard() {
  const [players, setPlayers] = useState<Player[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://isleofducks.net/api/player_data.php');
        if (!response.ok) {
          throw new Error('Failed to fetch player data');
        }
        const data: Player[] = await response.json();
        const sortedPlayers = data.sort((a, b) => b.level - a.level);
        setPlayers(sortedPlayers);
      } catch (error) {
        console.error('Error fetching player data:', error);
        setError('Failed to fetch player data. Please try again later.');
      }
    };

    fetchData();
  }, []);

  const getLastSyncRelative = (lastSync: string): string => {
    const syncDate = new Date(lastSync);
    return capitalize(formatDistanceToNow(syncDate, { addSuffix: true }));
  };

  const capitalize = (s: string): string => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div>
      <Navbar />
      <div className="playerleaderboard-content">
        <h1>Isle of Ducks Player Leaderboard</h1>
        <p>Leaderboards are currently under development, expect regular changes and slightly off statistics!</p>
        {error ? (
          <p>{error}</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Skyblock Level</th>
                <th>Networth</th> 
                <th>Weight</th>
                <th>Skill Average</th>
                <th>Slayers</th>
                <th>Catacombs</th>
                <th>Last Sync</th>
              </tr>
            </thead>
            <tbody>
              {players.map((player, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{player.name}</td>
                  <td>{player.level || 0}</td>
                  <td>{format(player.networth || 0)}</td>
                  <td>{player.weight !== null ? Math.floor(player.weight) : 0}</td>
                  <td>{player.skills !== null ? player.skills.toFixed(2) : 0}</td>
                  <td>{player.slayers !== null ? format(player.slayers) : 0}</td>
                  <td>{player.catacombs || 0}</td>
                  <td>{getLastSyncRelative(player.lastsync)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PlayerLeaderboard;
