import './Forms.css';
import Footer from './Footer';
import Navbar from './Navbar';

function Forms() {
  return (
    <body>
        <Navbar />
        <div className="forms-content">
          <h1>Forms</h1>
        </div>
        <Footer />
    </body>
  );
}

export default Forms;
