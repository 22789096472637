import React, { useState, useEffect } from 'react';
import './Home.css';
import Footer from './Footer';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

function Home() {
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalGuilds, setTotalGuilds] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://isleofducks.net/api/home.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => {
        setTotalPlayers(data.total_players);
        setTotalGuilds(data.total_guilds);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <body>
      <Navbar />
      <div className="home-content">
        <h1>Isle of Ducks</h1>
        <h2>Top 25 Skyblock Guild</h2>
        <p>Welcome to our website 👋, we're a top Skyblock guild comprised of late-end game players always looking to welcome new friends into our community. Our guild often hosts exciting giveaways and fun events with substantial prizes 💸, providing our members with enjoyable and rewarding experiences.</p>
        {/* <h2>Guild Alliance Leaderboards</h2>
        <p>Checkout our Skyblock leaderboards tracking <b>{totalPlayers}</b> players across <b>{totalGuilds}</b> guilds 📈</p>
        <Link to="/leaderboard/guild" className="home-link">Guild Leaderboard</Link>
        <Link to="/leaderboard/player" className="home-link">Player Leaderboard</Link> */}
      </div>
      <Footer />
    </body>
  );
}

export default Home;
